
body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar {
  max-width: 240px;
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
}

.sidebar-subitem-text {
  font-size: 0.8rem;
}

.navbar {
  background-color: #2c2a29;
  color: white;
  border-bottom: 7px solid #fdda24;

}

.sidebar{
  background-color: #2c2a29;
  color: white;
}

.nav {
  background-color: #2c2a29;
  color: white;
}

.dropdown-menu{
  padding: 0px !important;
}
.MuiTableCell-root{
  z-index: 1 !important;
}

.MTableHeader-header-49{
  z-index: 1 !important;
}
.MuiTableCell-head{
  z-index: 1 !important;
}

#basic-nav-dropdown {
  color: white;
  font-family: "CIBFont Sans Book";
  font-size: 120%;
}
#contenedor_sidenav {
  margin: 0%;
  position: fixed;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #2c2a29;
  overflow-x: hidden;
  transition: 0.4s;
  padding: 1rem 0;
  padding-top: 10%;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  #contenedor_sidenav {
    padding-top: 8%;
  }
}
@media (min-width: 1500px) {
  #contenedor_sidenav {
    padding-top: 6%;
  }
}
@media (max-width: 1065px) {
  #contenedor_sidenav {
    padding-top: 15%;
  }
}
#principal {
  height: 100%;
  width: 100%;
}
#contenedor_principal {
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
  min-height: 100%;
}

#contenedor_paginas {
  transition: margin-left .4s;
  padding: 1rem;
  padding-top: 0.5rem;
}

label {
  margin-left: 1%;
  margin-top: 0.5%;
}
#menu_titulo {
  margin-right: -15px;
  margin-left: -15px;
  height: 5em;
}

#nav_header{
  margin: 0%;
  top: 0;
  left: 0;
  z-index: 6;
  background-color: #2c2a29;
  padding: 1rem 0.5rem;
}

.estilo_titulos {
  color: #787878;
  margin: 3%;
}
#container_login {
  text-align: center;
  padding-top: 10%;
  padding-left: 20%;
}
.row_n_eval {
  margin-top: 2%;
}
#clasificacion_triage {
  margin-top: 5%;
}
#row_ancha {
  height: 5em;
}
#descripcion_prov {
  height: 100%;
}
#descripcion {
  height: 100%;
}
.btn-full {
  width: 100%;
}
.btn{
  color:#ffffff !important;
}
textarea:focus {
  border-color: #ffc107 !important;
  box-shadow: 0 1px 1px #ffc10741 inset, 0 0 8px#ffc107c0 !important;
  outline-color: 0 none !important;
}

.vult-title {
  color: white !important;
}

.css-1d9cbwv-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #ffc107 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
  color: #ffc107 !important;

}

select:focus {
  border-color: #ffc107 !important;
  box-shadow: 0 1px 1px #ffc10741 inset, 0 0 8px#ffc107c0 !important;
  outline-color: 0 none !important;
}
input:focus,
input[type]:focus {
  border-style: solid;
  border-color: #ffc107;
  box-shadow: 0 1px 1px #ffc10741 inset, 0 0 8px#ffc107c0;
  outline: 0 none;
}
.colorAuto:placeholder {
  background-color: "green" !important;
  color: white !important;
}

.buscar{
  padding: 1%;
  padding-left: 35px;
  padding: 1%;
}

.nav-margen{
  padding-left: 0px;
}

.fondo{
  background-color: white;
}


.table-hideable th {
  width: auto;
  transition: width .5s, margin .5s;
}
.btn-condensed.btn-condensed {
  padding: 0 5px;
  box-shadow: none;
}
/* use class to have a little animation */
.hide-col {
  width: 0px !important;
  height: 0px !important;
  display: block !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}
#botones {
  margin: 30px;
  margin-left: 20px;
  margin-right: 5px;
}

#botones2 {
  margin: 20px;
  margin-left: 10px;
  margin-right: 5px;
  
}

#valor{
  color: white;
}
#cot1{
  overflow-x: scroll;
  margin-left: 20px;
  margin-right: 20px;
  
}

#contenedorBotones{
  margin-left: 20px;
  margin-right: 20px;
}

.hidden { display: none }



.estiloa{
  background-color:#FFBF00;
  text-align: center;
  
  
  
}
.estilob{
  background-color:#2ECC71;
  text-align: center;
}

.estiloc{
  background-color:#2471A3;
  text-align: center;
  color: white;
}

.estilod{
  background-color:#2C3E50;
  color: white;
  text-align: center;
}
.estiloe{
  background-color:#27AE60;
  text-align: center;
  color: white;
}

#contenedoBotones{
   
  float: 'right'
}



.clasicenter { display: flex; 
  font-size: 20px;
  font-weight: bold;
  align-items: center; 
}



.tablevul {
  table-layout: auto;
  border-style: solid;
}

.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #6C757D;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

@font-face {
  font-family: "CIBFont Sans Regular";
  src: url("Fonts/CIBFontSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Bold";
  src: url("Fonts/CIBFontSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Italic";
  src: url("Fonts/CIBFontSans-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Book";
  src: url("Fonts/CIBFontSans-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "CIBFont Sans Regular", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.titulo {
  font-family: "CIBFont Sans Bold";
  font-size: 200%;
}

.subtitulo {
  font-family: "CIBFont Sans Regular";
  font-size: 160%;
}
.label {
  font-family: "CIBFont Sans Regular";
  font-size: 140%;
}
.texto {
  font-family: "CIBFont Sans Book";
  font-size: 120%;
}
.form-control {
  font-size: 120% !important;
}

.botonIngreso {
  color: #fff !important;
  background-color: #59cbe8 !important;
  border-color: #59cbe8 !important;
  min-width: 215px;
  min-height: 46px;
}

.botonIngreso2 {
  color: #fff !important;
  background-color: #59cbe8 !important;
  border-color: #59cbe8 !important;
  min-width: 300px;
  min-height: auto;
}

.botonDescargar {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  min-width: 215px;
  min-height: 46px;
}


.botonCargar {
  color: #fff !important;
  background-color: #59cbe8 !important;
  border-color: #59cbe8!important;
  min-width: 215px;
  min-height: 46px;
}

.botonCargar2 {
  color: #fff !important;
  background-color: #59cbe8  !important;
  border-color: #59cbe8  !important;
}

.botonCancelar{
  color: #fff !important;
  background-color: #C20C0C !important;
  border-color: #C20C0C  !important;
}


.botonPositivo {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  min-width: 300px;
  min-height: auto;
}
.botonPositivo2 {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
}

.botonPositivo3 {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  min-width: 215px;
  min-height: 46px;
}

.botonNegativo {
  color: #fff !important;
  background-color: #ff7f41 !important;
  border-color: #ff7f41 !important;
  min-width: 300px;
  min-height: auto;
}
.botonHipervinculo {
  color: #007bff !important;
  background-color: transparent !important;
  border: none !important;
}
.botonNegativo2 {
  color: #fff !important;
  background-color: #ff7f41 !important;
  border-color: #ff7f41 !important;
}
.botonGeneral {
  color: #fff !important;
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
  min-width: 300px;
  min-height: auto;
}
.botonGeneral2 {
  color: #fff !important;
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
}
.botonGeneral3 {
  color: #fff !important;
  background-color: #2c2a29 !important;
  border-color: #2c2a29 !important;
  min-width: 17.9045093vw;
  min-height: 4.6419098144vh;
}

.makeStyles-toolbar-2 {
  border-bottom: solid;
  border-bottom-color: #fdda24;
  border-bottom-width: 0.5vw;
}

#contenedor_paginas {
  padding: 2%;
}

.logonav{
  width: 40px;
  height: 40px;
}

::placeholder{
  font-family: "CIBFont Sans Book";
  font-size: 80%;
}

.innerBox{
  font-family: "CIBFont Sans Book";
  font-size: 80%;
}

.content-info{
  display: flex;
  justify-content: space-between;
  margin-top: 10%;
}
.content-info-col {
  text-align: center;
}

div.loading-mask {
  position: fixed;
  z-index: 1022;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.loading-mask {
  position: fixed;
  z-index: 1022;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.checkbox {
  align-items: center;
  margin-right: 10px; 
  margin-bottom: 10px;
}

.opaco {
  opacity: 0.6; 
  pointer-events: none; 
}